import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #10041b;
  font-size: 0.426rem;
  font-weight: bold;
`

export const BgImg = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/profile-icon/bg-account-credential.enc.png`,
})`
  position: relative;
  width: 8rem;
  height: 10.48rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const QRCodeWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.87rem;
  height: 2.87rem;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.2307rem;
`

export const TextBox = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 3.3rem;
  width: 2.87rem;
  display: flex;
  flex-direction: column;
  gap: 0.2307rem;
  font-size: 0.266rem;
  color: #ffffff;
`

export const BtnCopySharingUrl = styled.button`
  width: 100%;
  height: 0.923rem;
  background: var(--page-system-config--idcard-button-background);
  border-radius: 0.25rem;
  font-size: 0.3589rem;
`

export const SiteUrl = styled.div`
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.25rem;
  font-weight: 400;
`

export const Link = styled.a``
