import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from '@react-spring/web'
//
import { selectIsReadyToShowSplashAd, selectRouteDetectMessage } from 'redux/selector/app'
//
import preBootAnimation from './animation/pre-boot.json'
//
import { Wrapper, SplashImage, LoadingBox, Text, StyledAnimation, SplashTop, SplashBottom } from './Styled'
import actions from 'redux/action'
import SplashInviteCode from '../../SplashInviteCode'
import SplashVersionNumber from 'component/SplashVersionNumber'

/** App 入站讀取資料時的顯示畫面 */
export default function Splash() {
  const isReadyToShowSplashAd = useSelector(selectIsReadyToShowSplashAd)
  const routeDetectMessage = useSelector(selectRouteDetectMessage)
  const dispatch = useDispatch()

  const springStyles = useSpring({
    backgroundColor: 'black',
    // backgroundImage: `url(${process.env.PUBLIC_URL}/splash/boot-background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: isReadyToShowSplashAd ? 0 : 1,
    config: { duration: 800 },
    /** 動畫結束 */
    onRest: function () {
      dispatch(actions.updateIsShowingLaunchSplash(false))
    },
  })

  return (
    <>
      <Wrapper>
        <animated.div style={springStyles}>
          <SplashTop />
          <SplashBottom />
          <SplashImage />
          {routeDetectMessage && (
            <LoadingBox>
              <StyledAnimation animationData={preBootAnimation} />
              <Text>{routeDetectMessage}</Text>
            </LoadingBox>
          )}
          <SplashInviteCode />
          <SplashVersionNumber />
        </animated.div>
      </Wrapper>
    </>
  )
}
